<template>
  <v-app>
    <img alt="Vue logo" src="../assets/logo.png" class="responsive">
    <p class="responsive">Welcome to Everwell Automation Site. Please note that this is the {{this.isProd ? "Production":"Beta"}}
Environment to carry out the requests</p>
  </v-app>
</template>
<script>
import router from './../router'
import { mapState } from 'vuex'
import { routeNames } from '../../utils/dataValidations'
export default {
  name: 'App',
  computed: {
    ...mapState([
      'isAuth',
      'isProd'
    ])
  },
  mounted: function () {
    if (!this.isAuth) {
      router.push(routeNames.Home)
    }
    if (this.isProd === null){
      router.push(routeNames.Welcome)
    }
  }
}
</script>
<style scoped>
.responsive {
  width: fit-content;
  align-self: center;
}
</style>
